<template>
  <el-scrollbar wrap-class="scrollbar-wrapper">
    <el-menu :show-timeout="200" :default-active="$route.path" :collapse="isCollapse" mode="vertical">
      <sidebar-item v-for="route in displayedRoutes" :key="route.path" :item="route" :base-path="route.path" />
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex';
import SidebarItem from './SidebarItem';

export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters(['permission_routers', 'sidebar']),
    displayedRoutes() {
      return this.permission_routers.filter(x => x.children && x.children.length);
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  }
};
</script>
