<script>
import SvgIcon from '@/components/SvgIcon';

export default {
  name: 'MenuItem',
  functional: true,
  components: {
    SvgIcon
  },
  props: {
    icon: {
      default: '',
      type: String
    },
    title: {
      default: '',
      type: String
    }
  },
  render(h, context) {
    const { icon, title } = context.props;
    const vnodes = [];

    if (icon) {
      const svgIconVNode = h(SvgIcon, {
        props: {
          'icon-class': icon
        }
      });
      vnodes.push(svgIconVNode);
    }

    if (title) {
      vnodes.push(<span slot="title">{title}</span>);
    }
    return vnodes;
  }
};
</script>
